import request from '@/utils/request'
import ApiConst from '@/constant/api'
// import * as qs from 'qs'

export default class WssoApi {
  static handleProjectSync = (data: any) =>
    request({
      url: ApiConst.PROJECT_SYNC,
      method: 'post',
      data: data
    })

  static handleProjectItemSync = (data: any) =>
    request({
      url: ApiConst.PROJECT_ITEM_SYNC,
      method: 'post',
      data: data
    })

  static getLogo = () =>
    request({
      url: ApiConst.GET_LOGO,
      method: 'get'
    })

  static getLogoList = () =>
    request({
      url: ApiConst.LOGO_LIST,
      method: 'get'
    })

  static switchLogo = (data: any) =>
    request({
      url: ApiConst.SWITCH_LOGO,
      method: 'post',
      data: data
    })
}
