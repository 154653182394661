/**
 * @Author: siyukok
 * @Date: 2022/3/3 10:24 AM
 * @Version 1.0
 */
export default {
  login: {
    login: 'Login Out'
  },
  route: {
    Project_Center: 'Project Center',
    WSSO_Project: 'WSSO Project',
    Carbon_Credits_Project: 'Carbon Credits Project',
    RECs_Project: 'RECs Project'
  },
  WSSO_Project: {
    Search: 'Search',
    Project_Filter: 'Project Filter',
    Clear_Search: 'Clear Search',
    Sync_Project: 'Sync Project',
    Project_Homepage: 'Project Homepage',
    Project_ID: 'Project ID',
    Project_Name: 'Project Name',
    Project_Owner: 'Project Owner',
    Project_Assets_Amount: 'Project Assets Amount',
    Input_Project_ID_Please: 'Input Project ID Please',
    Cancel: 'Cancel',
    Submit: 'Submit',
    Success: 'Success',
    Total_Carbon_Credits: 'Total Carbon Credits',
    Project_Location: 'Project Location',
    Project_Address: 'Project Address',
    Installed_Capacity: 'Installed Capacity',
    Generation_Type: 'Generation Type',
    Coordinate_Inflection_Point: 'Coordinate Inflection Point',
    Methodology: 'Methodology',
    methodology_WSSO: 'This methodology has been officially certified by WSSO',
    Project_PDD: 'Project PDD',
    Project_PDD_On_Chain_Hash: 'Project PDD On-Chain Hash',
    Project_PVR: 'Project PVR',
    Project_PVR_On_Chain_Hash: 'Project PVR On-Chain Hash',
    Sync_Carbon_Credits: 'Sync Carbon Credits',
    ID: 'ID',
    Amount: 'Amount',
    Integrity_Score: 'Integrity Score',
    MR: 'MR',
    MR_On_Chain_Hash: 'MR On-Chain Hash',
    Certification: 'Certification',
    Certification_On_Chain_Hash: 'Certification On-Chain Hash',
    Serial_Number: 'Serial Number',
    State: 'State',
    Operate: 'Operate',
    Input_Carbon_Credits_ID_Please: 'Input Carbon Credits ID Please',
    RWA_Tokenization: 'RWA Tokenization',
    Collection_Information: 'Collection Information',
    RWA_Information: 'RWA Information',
    Carbon_Credits_ID: 'Carbon Credits ID',
    Carbon_Dioxide_Equivalent: 'Carbon Dioxide Equivalent',
    Carbon_Credits_Integrity_Score: 'Carbon Credits Integrity Score',
    Carbon_Credits_Serial_Number: 'Carbon Credits Serial Number',
    Carbon_Credits_MR: 'Carbon Credits MR',
    Carbon_Credits_Certification: 'Carbon Credits Certification',
    Carbon_Credits_MR_On_Chain_Hash: 'Carbon Credits MR On-Chain Hash',
    Carbon_Credits_Certification_On_Chain_Hash: 'Carbon Credits Certification On-Chain Hash',
    Agency_Date: 'Agency Date',
    RWA_Tokenization_InCapture: 'Please scan the QR code to complete the RWA Tokenization in the Ferghana Wallet.',
    RWA_Authentication: 'RWA Authentication',
    RWA_Authenticate_process: 'RWA Authenticateshould undergo multi-signature verification on the identity chain by business entities such as accounting firms, law firms, licensed financial institutions, asset development agencies, and asset owners. The Proof of Concept (POC) simulates this process.',
    To_Be_Authenticated_by_Owner: 'To Be Authenticated by Owner',
    Authenticated_by_Owner: 'Authenticated by Owner',
    Owner_Authenticate: 'Owner Authenticate',
    To_Be_Authenticated_by_RWA_Development_Organization: 'To Be Authenticated by RWA Development Organization',
    Authenticated_by_RWA_Development_Organization: 'Authenticated by RWA Development Organization',
    RWA_Development_Organization_Authenticate: 'RWA Development Organization Authenticate',
    To_Be_Authenticated_by_Accounting_Firm: 'To Be Authenticated by Accounting Firm',
    Authenticated_by_Accounting_Firm: 'Authenticated by Accounting Firm',
    Accounting_Firm_Authenticate: 'Accounting Firm Authenticate',
    To_Be_Authenticated_by_Low_Firm: 'To Be Authenticated by Low Firm',
    Authenticated_by_Low_Firm: 'Authenticated by Low Firm',
    Low_Firm_Authenticate: 'Low Firm Authenticate',
    To_Be_Authenticated_by_Licensed_Financial_Organization: 'To Be Authenticated by Licensed Financial Organization',
    Authenticated_by_Licensed_Financial_Organization: 'Authenticated by Licensed Financial Organization',
    Licensed_Financial_Organization_Authenticate: 'Licensed Financial Organization Authenticate',
    RWA_Authentication_InCapture_Vault: 'Please scan the QR code to complete the RWA Authentication in the Ferghana Wallet.',
    Authentication_Hash: 'Authentication Hash',
    RWA_Tokenized: 'RWA Tokenized',
    RWA_To_Be_Authenticated: 'RWA To Be Authenticated',
    RWA_Authenticated: 'RWA Authenticated'
  },
  Carbon_Credits_Project: {
    Register_Project: 'Register Project',
    Project_Homepage: 'Project Homepage',
    Project_Type: 'Project Type',
    Project_Standard: 'Project Standard',
    Carbon_Credits_Project_Registration: 'Carbon Credits Project Registration',
    Input_Project_Owner_DID_Please: 'Input Project Owner DID Please',
    Input_Project_Standard_Please: 'Select Project Standard Please',
    Input_Project_Homepage_Please: 'Input Project Homepage Please',
    Input_Project_Name_Please: 'Input Project Name Please',
    Input_Project_Location_Please: 'Input Project Location Please',
    Input_VCS_Type_Please: 'Input VCS Type Please',
    Input_VCS_Methodology_Please: 'Input VCS Methodology Please',
    Input_VCS_Validator_Please: 'Input VCS Validator Please',
    Input_Project_Area_Please: 'Input Project Area Please',
    Select_Registration_Date_Please: 'Select Registration Date Please',
    Certification_Period_Start_Date: 'Start Date',
    Certification_Period_End_Date: 'End Date',
    VCS_Type: 'VCS Type',
    VCS_Methodology: 'VCS Methodology',
    VCS_Validator: 'VCS Validator',
    Project_Area: 'Project Area',
    Registration_Date: 'Registration Date',
    Certification_Period: 'Certification Period',
    ETH_Address: 'ETH Address',
    Input_ETH_Address_Please: 'Input ETH Address Please',
    Select_Certification_Period_Please: 'Select Certification Period Please',
    Register_VCUs: 'Register VCUs',
    Agency_Date: 'Agency Date',
    Amount: 'Amount',
    Serial_Number: 'Serial Number',
    State: 'State',
    Assets_Measurement: 'Assets Measurement',
    Select_Register_VCUs_Please: 'Select Register VCUs Please',
    Input_Amount_Please: 'Input Amount Please',
    Input_Serial_Number_Please: 'Input Serial Number Please',
    Project_Country: 'Project Country',
    Input_Project_Country_Please: 'Input Project Country Please',
    Commissioning_Date: 'Commissioning Date',
    Select_Commissioning_Date_Please: 'Select Commissioning Date Please',
    Expiry_Date: 'Expiry Date',
    Select_Expiry_Date_Please: 'Select Expiry Date Please',
    Technology: 'Technology',
    Input_Project_Technology_Please: 'Input Project Technology Please',
    Primary_Fuel: 'Primary Fuel',
    Input_Project_Primary_Fuel_Please: 'Input Project Primary Fuel Please',
    Capacity: 'Capacity (MW)',
    Input_Project_Capacity_Please: 'Input Project Capacity (MW) Please',
    Register_I_RECs: 'Register I-RECs',
    Agency_Date_I_RECs: 'Agency Date',
    Amount_I_RECs: 'Amount',
    Serial_Number_I_RECs: 'Serial Number',
    Select_Agency_Date_Please: 'Select Agency Date Please',
    Input_IRECs_Amount_Please: 'Input I-RECs Amount Please',
    Input_IRECs_Serial_Number_Please: 'Input I-RECs Serial Number Please'
  }
}
