import { UserModule } from '@/store/modules/user'
import UserStorage from '@/storages/user'
import jwtDecode, { JwtPayload } from 'jwt-decode'

interface TokenData {
  access_token: string
  expires_in:number
  refresh_token: string
  user_info: {
    Name: string
    CardNo: string
  }
}

interface DIDToken extends JwtPayload {
  pro: any
}

interface HasuraDIDToken extends DIDToken {
  'https://hasura.io/jwt/claims': {
    'x-hasura-allowed-roles': string[]
    'x-hasura-default-role': string
  }
}

export interface UserInfo {
  PhoneNumber: string
}

/* eslint-disable camelcase */
/**
 * @Author: siyukok
 * @Date: 2022/2/10 11:25 AM
 * @Version 1.0
 */
export default class UserService {
  // 登出
  static async logout(useApi = true) {
    UserStorage.removeToken()
    UserStorage.removeExpireAt()
    UserStorage.removeRefreshToken()
    UserStorage.removeUserInfo()
    UserModule.LogOut()
  }

  // 使用refreshToken重新获取accessToken,距离过期还有两小时
  // static async checkAndRefreshToken() {
  //   if (UserModule.oauth.refreshToken && UserModule.oauth.expireAt > 0 && (UserModule.oauth.expireAt - Math.ceil(Date.now() / 1000)) < 3600 * 2) {
  //     const { data } = await UserApi.getTokenByRefreshToken(UserModule.oauth.refreshToken)
  //     if (!data) {
  //       return
  //     }
  //     // todo check refreshToken is working
  //     this.processTokenData(data)
  //   }
  // }

  static processTokenData(data: TokenData) {
    const {
      access_token: accessToken,
      expires_in,
      refresh_token: refreshToken,
      user_info
    } = data
    const expireAt = Math.floor(Date.now().valueOf() / 1000) + Math.floor(expires_in / 1000)
    UserModule.DpLogin({
      accessToken,
      expireAt,
      refreshToken
    })
    UserStorage.accessToken = accessToken
    UserStorage.refreshToken = refreshToken
    UserStorage.expireAt = expireAt
    UserStorage.userInfo = user_info
  }

  static decodeToken(token: string): HasuraDIDToken | undefined {
    try {
      return jwtDecode(token)
    } catch (err) {
      console.error(err)
    }
  }
}
