


















































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import SidebarItem from './SidebarItem.vue'
import variables from '@/styles/_variables.scss'
import { PermissionModule } from '@/store/modules/permission'
import WssoApi from '@/api/wsso'

@Component({
  name: 'SideBar',
  components: {
    SidebarItem
  }
})
export default class extends Vue {
  private logo = ''
  private width = ''
  private height = ''
  created() {
    this.getLogo()
  }

  get sidebar() {
    return AppModule.sidebar
  }

  get routes() {
    return PermissionModule.routes
  }

  get variables() {
    return variables
  }

  get activeMenu() {
    const route = this.$route
    const {
      meta,
      path
    } = route
    if (meta.activeMenu) {
      return meta.activeMenu
    }
    return path
  }

  get isCollapse() {
    return !this.sidebar.opened
  }

  private async getLogo() {
    const { data } = await WssoApi.getLogo()
    if (data) {
      this.logo = data.ecaasLogo
      this.width = data.ecaasLogoWidth
      this.height = data.ecaasLogoHeight
      const link = document.querySelector('link[rel="icon"]') as HTMLLinkElement
      if (link && data.favicon) {
        link.href = data.favicon
      }
    }
  }
}
